<template>
  <section class="w-full custom-class">
    <div v-if="cargando" style="width:100%; height: 300px" class="rounded-md icono-carga flex justify-center items-center relative">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" style="width:100%; height: 300px"/>
    </div>
    <div class="m-4" v-else>
      <Button
        label="Buscar"
        icon="pi pi-search"
        class="p-button-xs mb-4"
        @click="handleSearch"
      />
      <Button
        label="Limpiar"
        icon="pi pi-trash"
        class="p-button-xs mb-4 p-button-danger"
        @click="handleClearFilters"
      />
      <div v-if="costos[0]" class="grid grid-cols-12 gap-2 pb-4">

        <!-- ------costos clientes----- -->
        <div class="accordion-item col-span-12 text-xs">
          <div id="faq-accordion-content-2" class="accordion-header flex justify-between col-span-2">
            <button
              class="accordion-button text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faq-accordion-collapse-1"
              aria-expanded="true"
              aria-controls="faq-accordion-collapse-1"
            >
              <span class="text-left font-bold col-span-6 text-sm"></span>
            </button>
          </div>
          <div id="faq-accordion-collapse-2" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-1">
            <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
              <div class="col-span-12 lg:col-span-12">
                <DataTable
                  :value="getCostosClientes"
                  :paginator="true"
                  :rows="10"
                  :rowsPerPageOptions="[10, getCostosClientes.length]"
                  v-model:expandedRows="expandedRowsCostos"
                  @rowExpand="onRowExpandCostos"
                  @rowCollapse="onRowCollapseCostos"
                  responsiveLayout="stack"
                  breakpoint="960px"
                  showGridlines
                  class="letra p-datatable-sm text-right mt-2"
                  :rowClass="() => 'rowColorCostos'"
                >
                  <Column :expander="true" headerStyle="width: 3rem"/>
                  <Column header="Bodega" field="bodega">
                  </Column>
                  <Column header="Fecha" field="fecha">
                  </Column>
                  <Column header="Prom-Capita" field="capita">
                    <template #body="{ data }">
                      {{ $h.formatNumber(data.capita) }}
                    </template>
                  </Column>
                  <Column header="Prom-Evento" field="evento">
                    <template #body="{ data }">
                      {{ $h.formatNumber(data.evento) }}
                    </template>
                  </Column>
                  <Column header="MaxCapita" field="maxcapita">
                    <template #body="{ data }">
                      {{ $h.formatNumber(data.maxcapita) }}
                    </template>
                  </Column>
                  <Column header="MaxEvento" field="maxevento">
                    <template #body="{ data }">
                      {{ $h.formatNumber(data.maxevento) }}
                    </template>
                  </Column>
                  <Column header="MinCapita" field="mincapita">
                    <template #body="{ data }">
                      {{ $h.formatNumber(data.mincapita) }}
                    </template>
                  </Column>
                  <Column header="MinEvento" field="minevento">
                    <template #body="{ data }">
                      {{ $h.formatNumber(data.minevento) }}
                    </template>
                  </Column>
                  <Column header="Total" field="total">
                    <template #body="{ data }">
                      {{ $h.formatNumber(data.total) }}
                    </template>
                  </Column>
                  <template #expansion="slotProps">
                    <div class="">
                      <DataTable
                        :value="slotProps.data.detalle"
                        :paginator="true"
                        :rows="10"
                        :rowsPerPageOptions="[10, slotProps.data.detalle.length]"
                        showGridlines
                        responsiveLayout="scroll"
                        class="p-datatable-sm p-2 text-sm"
                      >
                        <Column header="" field="">
                        </Column>
                        <Column header="Bodega" field="bodega">
                        </Column>
                        <Column header="Fecha" field="fecha">
                        </Column>
                        <Column header="Capita" field="capita">
                          <template #body="{ data }">
                            {{ $h.formatNumber(data.capita) }}
                          </template>
                        </Column>
                        <Column header="Evento" field="evento">
                          <template #body="{ data }">
                            {{ $h.formatNumber(data.evento) }}
                          </template>
                        </Column>
                        <Column header="Total" field="total">
                          <template #body="{ data }">
                            {{ $h.formatNumber(data.total) }}
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                  </template>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full col-span-12 flex justify-end items-center my-3">
          <button
            type="button"
            class="bg-green-600 text-white px-3 py-2 ml-3 rounded-md "
            @click="handleDownloadXls"
          >
            Descargar XLS
          </button>
        </div>
        <Divider class="col-span-12 mt-0" />

        <div class="accordion-item col-span-12 text-xs">
          <div id="faq-accordion-content-9" class="accordion-header flex justify-between col-span-2">
            <button
              class="accordion-button text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faq-accordion-collapse-9"
              aria-expanded="true"
              aria-controls="faq-accordion-collapse-9"
            >
              <span class="text-left font-bold col-span-6 text-sm">Analisis x mes</span>
            </button>
          </div>
          <div id="faq-accordion-collapse-2" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-1">
            <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
              <div class="col-span-12 lg:col-span-12">
                <DataTable
                  :value="getAnalisisMed"
                  v-model:expandedRows="expandedRowsCostos"
                  @rowExpand="onRowExpandCostos"
                  @rowCollapse="onRowCollapseCostos"
                  responsiveLayout="stack"
                  breakpoint="960px"
                  showGridlines
                  class="letra p-datatable-sm text-right mt-2"
                  :rowClass="() => 'rowColorIngresos'"
                >
                  <Column :expander="true" headerStyle="width: 3rem"/>
                  <Column header="Analisis" field="analisis">
                  </Column>
                  <Column header="Entradas" field="entradas">
                    <template #body="{ data }">
                      {{ $h.formatNumber(data.entradas) }}
                    </template>
                  </Column>
                  <Column header="Cantidad" field="cantidad">
                    <template #body="{ data }">
                      {{ $h.formatNumber(data.cantidad) }}
                    </template>
                  </Column>
                  <Column header="Medicamentos" field="medicamentos">
                    <template #body="{ data }">
                      {{ $h.formatNumber(data.medicamentos) }}
                    </template>
                  </Column>
                  <template #expansion="slotProps">
                    <div class="">
                      <DataTable
                        :value="slotProps.data.detalle"
                        :rows="12"
                        showGridlines
                        responsiveLayout="scroll"
                        class="p-datatable-sm p-2 text-sm"
                      >
                        <Column header="" field="">
                        </Column>
                        <Column header="Fecha" field="fecha">
                        </Column>
                        <Column header="Entradas" field="entradas">
                          <template #body="{ data }">
                            {{ $h.formatNumber(data.entradas) }}
                          </template>
                        </Column>
                        <Column header="Cantidad" field="cantidad">
                          <template #body="{ data }">
                            {{ $h.formatNumber(data.cantidad) }}
                          </template>
                        </Column>
                        <Column header="Medicamentos" field="medicamentos">
                          <template #body="{ data }">
                            {{ $h.formatNumber(data.medicamentos) }}
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                  </template>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

        <Divider class="col-span-12 mt-0" />

        <div class="accordion-item col-span-12 text-xs">
          <div id="faq-accordion-content-9" class="accordion-header flex justify-between col-span-2">
            <button
              class="accordion-button text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#faq-accordion-collapse-9"
              aria-expanded="true"
              aria-controls="faq-accordion-collapse-9"
            >
              <span class="text-left font-bold col-span-6 text-sm">Analisis x Proveedor</span>
            </button>
          </div>
          <div id="faq-accordion-collapse-2" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content-1" data-bs-parent="#faq-accordion-1">
            <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
              <div class="col-span-12 lg:col-span-12">
                <DataTable
                  :value="getAnalisisMedProv"
                  v-model:expandedRows="expandedRowsCostos"
                  @rowExpand="onRowExpandCostos"
                  @rowCollapse="onRowCollapseCostos"
                  responsiveLayout="stack"
                  breakpoint="960px"
                  showGridlines
                  class="letra p-datatable-sm text-right mt-2"
                  :rowClass="() => 'rowColorUtilBruta'"
                >
                  <Column :expander="true" headerStyle="width: 3rem"/>
                  <Column header="Analisis" field="analisis">
                  </Column>
                  <Column header="Entradas" field="entradas">
                    <template #body="{ data }">
                      {{ $h.formatNumber(data.entradas) }}
                    </template>
                  </Column>
                  <Column header="Cantidad" field="cantidad">
                    <template #body="{ data }">
                      {{ $h.formatNumber(data.cantidad) }}
                    </template>
                  </Column>
                  <Column header="Medicamentos" field="medicamentos">
                    <template #body="{ data }">
                      {{ $h.formatNumber(data.medicamentos) }}
                    </template>
                  </Column>
                  <template #expansion="slotProps">
                    <div class="">
                      <DataTable
                        :value="slotProps.data.detalle"
                        :paginator="true"
                        :rows="10"
                        :rowsPerPageOptions="[10, slotProps.data.detalle.length]"
                        showGridlines
                        responsiveLayout="scroll"
                        class="p-datatable-sm p-2 text-sm"
                      >
                        <Column header="" field="">
                        </Column>
                        <Column header="Proveedor" field="proveedor">
                        </Column>
                        <Column header="Entradas" field="entradas">
                          <template #body="{ data }">
                            {{ $h.formatNumber(data.entradas) }}
                          </template>
                        </Column>
                        <Column header="Cantidad" field="cantidad">
                          <template #body="{ data }">
                            {{ $h.formatNumber(data.cantidad) }}
                          </template>
                        </Column>
                        <Column header="Medicamentos" field="medicamentos">
                          <template #body="{ data }">
                            {{ $h.formatNumber(data.medicamentos) }}
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                  </template>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import { computed, ref } from 'vue'
import { apiListCostClientes, apiListCostBodega, apiListCostGastos, apiAnalisisMedicamentosMes, apiAnalisisMedProv } from '../../service'
import { useStore } from 'vuex'
import axios from 'axios'
import { helper } from '../../../../../../../utils/helper'
import currency from 'currency.js'
import { FilterMatchMode } from 'primevue/api'
import { messageWarning } from '../../../../../../../libs/mensajes'

export default {
  name: 'ListaCostos',
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  setup (props, context) {
    const store = useStore()
    const cargando = ref(false)
    const costos = ref([])
    const getItemsGastos = ref([])
    const getItemsGastosGeneral = ref([])
    const costosBodega = ref([])
    const searchNivelBodega = ref('')
    const getCostos = ref([])
    const getUtilidadBruta = ref([])
    const getGastos = ref([])
    const showHideIngCosCliente = ref(false)
    const getItemsUtilidad = ref([])
    const expandedRowsGastos = ref([])
    const expandedRowsCostos = ref([])
    const gastoDetails = ref([])
    const costoDetails = ref([])

    const getCostosClientes = ref([])
    const getAnalisisMed = ref([])
    const getAnalisisMedProv = ref([])

    const getterFilters = computed(() => {
      return store.getters['filtersCardCostosClientes/getFilters']
    })

    const loadData = () => {
      cargando.value = true
      return apiListCostClientes(getterFilters.value).then(({ status, data: response }) => {
        // console.log('Response:------ ', response.data)

        // console.log('getterFilters-------------', getterFilters.value)

        const claves = Object.keys(response.data[0])
        costos.value = claves.map((item) => {
          return {
            [`${item}`]: response.data[0][item],
            [`${item}General`]: response.data[0][item]
          }
        })
        getCostosClientes.value = response.data
        cargando.value = false
        // console.log('Costos: ', costos.value)
      }).catch(err => {
        console.error(err.message)
      })
    }

    const loadAnalisis = () => {
      cargando.value = true
      return apiAnalisisMedicamentosMes(getterFilters.value).then(({ status, data: response }) => {
        const claves = Object.keys(response.data[0])
        costos.value = claves.map((item) => {
          return {
            [`${item}`]: response.data[0][item],
            [`${item}General`]: response.data[0][item]
          }
        })
        getAnalisisMed.value = response.data
        // console.log('Costos: ', costos.value)
        cargando.value = false
      }).catch(err => {
        console.error(err.message)
      })
      }

    const loadAnalisisProv = () => {
      cargando.value = true
      return apiAnalisisMedProv(getterFilters.value).then(({ status, data: response }) => {
        const claves = Object.keys(response.data[0])
        costos.value = claves.map((item) => {
          return {
            [`${item}`]: response.data[0][item],
            [`${item}General`]: response.data[0][item]
          }
        })
        getAnalisisMedProv.value = response.data
        cargando.value = false
      }).catch(err => {
        console.error(err.message)
      })
      }

    /* const loadDataClientesBodegas = () => {
      cargando.value = true
      return apiListClientesBodegas(response => {
        console.log(response)
        return response
      })
    } */

    const loadDataGastos = () => {
      cargando.value = true
      return apiListCostGastos(getterFilters.value).then(({ status, data: response }) => {
        getItemsGastos.value = response.dataGeneral.map((item, i) => {
          for (const obj2 of response.data) {
            if (item.cmayor === obj2.cmayor) {
              obj2.GastoGeneral = item.Gasto
              obj2.GastoFiltrado = obj2.gastoAsig + obj2.gastoDist
              return obj2
            }
          }

          return {
            CMayor: item.cmayor,
            Gasto: 0,
            GastoFiltrado: 0,
            GastoGeneral: item.Gasto,
            gastoAsig: 0,
            gastoDist: 0,
            percent: 0
          }
        })

        for (const obj of getItemsGastos.value) {
          if (obj.cmayor === undefined || obj.cmayor === null) {
            obj.cmayor = ''
          }
        }
      }).catch(err => {
        console.error(err.message)
      })
    }

    const loadDataBodega = () => {
      cargando.value = true
      return apiListCostBodega(getterFilters.value).then(({ status, data: response }) => {
        costosBodega.value = response.data
        for (const obj of costosBodega.value) {
          if (obj.bodega === undefined || obj.bodega === null) {
            obj.bodega = ''
          }
        }
      }).catch(err => {
        console.error(err.message)
      })
    }

    const getAllValuesBodega = computed(() => {
      return costosBodega.value.map(res => {
        const utilOperacional = res.ingreso - (res.costo + res.gastoTotal)
        const percentUtilOperacional = ![NaN, undefined, null, 0, ''].includes(res.ingreso) ? ((utilOperacional / res.ingreso) * 100).toFixed(0) + '%' : '0%'
        const percentCostoIngreso = ![NaN, undefined, null, 0, ''].includes(res.ingreso) ? ((res.costo / res.ingreso) * 100).toFixed(0) + '%' : '0%'
        const percentMargen = ![NaN, undefined, null, 0, ''].includes(res.ingreso) ? ((1 - (res.costo / res.ingreso)) * 100).toFixed(0) + '%' : '0%'
        return {
          ...res,
          utilOperacional: helper.formatCurrency(utilOperacional, 0),
          percentUtilOperacional,
          percentCostoIngreso,
          percentMargen
        }
      })
    })

    const getItemsBodega = computed(() => {
      return getAllValuesBodega.value.filter(a => {
        return a.bodega.toLowerCase().includes(searchNivelBodega.value.toLowerCase())
      })
    })

    /**/
    const totalCostosBodega = computed(() => {
      let total = 0
      for (const pres of getItemsBodega.value) {
        total += pres.costo ? pres.costo : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })
    const totalGastosBodega = computed(() => {
      let total = 0
      for (const pres of getItemsBodega.value) {
        total += pres.gastoTotal ? pres.gastoTotal : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })

    /**/
    const totalGastoGeneral = computed(() => {
      let total = 0
      for (const pres of getItemsGastos.value) {
        total += pres.GastoGeneral ? pres.GastoGeneral : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })
    const totalGasto = computed(() => {
      let total = 0
      for (const pres of getItemsGastos.value) {
        total += pres.GastoFiltrado ? pres.GastoFiltrado : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })
    const totalGastoPercent = computed(() => {
      let total = 0
      for (const pres of getItemsGastos.value) {
        total += pres.percent ? pres.percent : 0
      }

      return `${Math.round(total)}%`
    })
    const totalGastoAsignado = computed(() => {
      let total = 0
      for (const pres of getItemsGastos.value) {
        total += pres.gastoAsig ? pres.gastoAsig : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })
    const totalGastoDistribuido = computed(() => {
      let total = 0
      for (const pres of getItemsGastos.value) {
        total += pres.gastoDist ? pres.gastoDist : 0
      }

      return `$${currency(total, { separator: '.', precision: 0, decimal: ',', symbol: '' }).format()}`
    })

    const totalUtilidadOperacionalGeneral = computed(() => {
      return costos.value[0].ingresoGeneral - costos.value[1].costoGeneral - costos.value[4].gastoTotalGeneral
    })
    const percentUtilidadOperacionalGeneral = computed(() => {
      return (((costos.value[0].ingresoGeneral - costos.value[1].costoGeneral - costos.value[4].gastoTotalGeneral) / costos.value[0].ingresoGeneral) * 100).toFixed(0) + '%'
    })

    const handleSearch = () => {
      if ([undefined, null].includes(getterFilters.value.month_start)) {
        messageWarning('Por favor debe seleccionar un mes de inicio para realizar la consulta.')
        return false
      }
      if ([undefined, null].includes(getterFilters.value.month_end)) {
        messageWarning('Por favor debe seleccionar un mes final para realizar la consulta.')
        return false
      }

      Promise.all([
        loadData(),
        loadAnalisis(),
        loadAnalisisProv(),
        loadDataGastos(),
        loadDataBodega()
      ]).then(() => {
        getItemsUtilidad.value = [
          {
            concepto: 'Utilidad Operacional',
            totalGeneral: totalUtilidadOperacionalGeneral,
            percentGeneral: percentUtilidadOperacionalGeneral
          },
          {
            concepto: 'Impuesto de Renta',
            percentGeneral: '',
            percentFiltrado: ''
          },
          {
            concepto: 'Utilidad Final',
            percentGeneral: '',
            percentFiltrado: ''
          }
        ]
        cargando.value = false
        showHideIngCosCliente.value = false
      })
    }

    const handleClearFilters = () => {
      store.dispatch('filtersCardCostosClientes/getClearAction', {
        month_start: null,
        month_end: null,
        year: new Date().getFullYear(),
        customer: null,
        bodega: null,
        dptobodega: null,
        municUsers: null,
        modalidad: null
      }).then(() => {
        handleSearch()
      })
    }

    const filtersTableGastos = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      cmayor: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })

    const filtersTableBodega = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      bodega: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })

    const onRowCollapseGastos = () => {
      gastoDetails.value = []
    }
    const onRowCollapseCostos = () => {
      costoDetails.value = []
    }

    const onRowExpandGastos = (event) => {
      // console.log(event)
    }
    const onRowExpandCostos = (event) => {
      // console.log(event)
    }

    const handleDownloadXls = () => {
      const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/operation-report/analisis-med-prov-XLS`
         const res = axios.getUri({ url, params: getterFilters.value })
           window.open(res, '_blank')
    }

    return {
      // loadDataClientesBodegas,
      searchNivelBodega,
      getItemsBodega,
      getAllValuesBodega,
      costos,
      getItemsGastos,
      getItemsGastosGeneral,
      handleSearch,
      handleClearFilters,
      handleDownloadXls,
      cargando,
      costosBodega,
      totalCostosBodega,
      totalGastosBodega,
      totalGastoGeneral,
      totalGasto,
      totalGastoPercent,
      totalGastoAsignado,
      totalGastoDistribuido,
      totalUtilidadOperacionalGeneral,
      percentUtilidadOperacionalGeneral,
      filtersTableGastos,
      filtersTableBodega,
      getItemsUtilidad,
      getCostos,
      getUtilidadBruta,
      getGastos,
      showHideIngCosCliente,
      expandedRowsCostos,
      expandedRowsGastos,
      getCostosClientes,
      getAnalisisMed,
      getAnalisisMedProv,
      onRowCollapseGastos,
      onRowCollapseCostos,
      onRowExpandGastos,
      onRowExpandCostos
    }
  }
}
</script>

<style lang="scss">
.letra{
  font-size: 0.7em;
}
.custom-class{
  .p-component{
    font-size: 0.7rem;
  }
  .p-divider.p-divider-horizontal {
    margin: 0;
    padding: 0 1rem;
  }
  .p-divider.p-divider-horizontal.details {
    margin: 0.5rem 0;
  }
  .p-divider.p-divider-vertical {
    margin: 0;
    padding: 0 1rem;
  }
  .rowColorIngresos {
    background-color: #E2EFDA !important;
  }
  .rowColorCostos {
    background-color: #DDEBF7 !important;
  }
  .rowColorGastos {
    background-color: #FFDDDD !important;
  }
  .rowColorUtilBruta {
    background-color: #FFFFDD !important;
  }
}
</style>
